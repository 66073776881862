<template>
  <v-form>
    <v-container
      class="px-lg-10 mx-0"
    >
      <v-card class="px-10 py-5">
        <v-row>
          <v-col>
            <div class="font-weight-bold text-h4 mb-n2 mt-4">
              Informasi Umum
            </div>
          </v-col>
        </v-row>

        <v-row class="mb-n2">
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            Divisi
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            {{ dataEmployee.pos_divisi }}
          </v-col>
        </v-row>

        <v-row class="mb-n2">
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            Posisi
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            {{ dataEmployee.pos_name }}
          </v-col>
        </v-row>

        <v-row class="mb-n2">
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            Email Travikr
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            {{ dataEmployee.email }}
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="font-weight-bold text-h4 mb-n2 mt-4">
              Data Pribadi
            </div>
          </v-col>
        </v-row>

        <v-row class="mb-n2">
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            Nama
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            {{ dataEmployee.fname }} {{ }} {{ dataEmployee.lname }}
          </v-col>
        </v-row>

        <v-row class="mb-n2">
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            Email Pribadi
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            {{ dataEmployee.email_personal }}
          </v-col>
        </v-row>

        <v-row class="mb-n2">
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            Nomor HP
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            {{ dataEmployee.phone }}
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="font-weight-bold text-h4 mb-n2 mt-4">
              Data Alamat
            </div>
          </v-col>
        </v-row>

        <v-row class="mb-n2">
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            Alamat
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
            {{ dataEmployee.address }}, {{ dataEmployee.address_string }}
          </v-col>
        </v-row>

        <v-row class="mb-n2">
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            Kode Pos
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            {{ dataEmployee.poscode }}
          </v-col>
        </v-row>

        <v-row class="mt-3 py-5">
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-btn
              color="orange"
              @click="cancelButtonClick"
            >
              KEMBALI
            </v-btn>
          <!-- <v-btn
            color="purple"
            @click="saveButtonClick"
          >
            UBAH STATUS
          </v-btn> -->
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-form>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['existingDataEmployee'],
    data: () => ({
      employee: [],
      dataEmployee: {
        address: '',
        address_string: '',
        created_time: '',
        email: '',
        email_personal: '',
        fname: '',
        hash: '',
        id: 0,
        lname: '',
        phone: '',
        pos_divisi: '',
        pos_name: '',
        poscode: 0,
        status: 1,
        status_string: '',
      },
    }),

    computed: {
    },

    watch: {
    },

    created () {
      this.initialize(this.$route.params.id)
    },

    methods: {
      initialize (id) {
        const vm = this
        const requestBody = new URLSearchParams()
        requestBody.append('emp_hash', id)

        axios.post('/t/employee/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.dataEmployee = res.data.data.detail
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      saveButtonClick () {
        const requestBody = new URLSearchParams()
        requestBody.append('emp_hash', this.dataEmployee.hash)

        axios.post('/t/employee/mod/status', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
          }
          this.$router.push({ name: 'Employee' })
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      cancelButtonClick () {
        this.$router.push({ name: 'Employee' })
      },
    },
  }
</script>
